import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { AppBar, Avatar, Button, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar, Tooltip } from '@mui/material';
import { Link, } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import LogoGroupeProPress from '@/assets/images/logo_groupe_propress-white.png'
import useAuthententicatedProfile from '@/hooks/useAuthententicatedProfile';
import { Fragment, useMemo, useState } from 'react';

interface Props {
    navItems?: Array<{ name: string, path: string }>,
    appLogo?: string,
    appIcon?: string,
    drawerIcon?: string | null,
    drawerWidth?: number
    window?: () => Window;
}
const DRAWER_WIDTH = 240;

export default function Header(props: Props) {

    const {
        navItems,
        appLogo = LogoGroupeProPress,
        appIcon = LogoGroupeProPress,
        drawerIcon: propDrawerIcon,
        window,
        drawerWidth = DRAWER_WIDTH,
    } = props
    const [mobileOpen, setMobileOpen] = useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const profile = useAuthententicatedProfile()
    const avatar = useMemo(() => {
        if (profile) {
            if (profile?.avatar) {
                return profile?.avatar

            } else {
                return profile?.kiosk?.logoUrl
            }
        }

        return null
    }, [profile])
    const drawerIcon = useMemo(() => {
        if (propDrawerIcon !== null) {
            return propDrawerIcon || appLogo
        }
        return undefined
    }, [propDrawerIcon, appLogo])

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            {drawerIcon ? (
                <Typography
                    variant="h6"
                    sx={{
                        my: 2,
                        mx: 2,
                        '& img': {
                            maxHeight: 40,
                            width: '100%',
                            marginRight: '10px'
                        }
                    }}>
                    <Link to={"/"}>
                        <img
                            src={drawerIcon}
                            alt="Logo"
                        />
                    </Link>
                </Typography>
            ) : null}
            <Divider />
            <List>
                {navItems?.map((item) => (
                    <ListItem key={item.name} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Fragment>

            <AppBar component="nav" enableColorOnDark>
                <Toolbar variant='regular' >
                    {navItems && navItems?.length > 0 ? (
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                    ) : null}
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'none', sm: 'block' },
                            '& img': {
                                maxHeight: 40,
                                marginRight: '10px'
                            }
                        }}
                    >
                        <Link to={"/"}>
                            <img
                                src={appIcon}
                                alt="Logo"
                            />
                        </Link>
                    </Typography>

                    <Box sx={{ display: { sm: 'none', marginLeft: 'auto' } }}>

                        <IconButton >
                            <Avatar alt={profile?.fullName} src={profile?.avatar} />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {navItems?.map((item) => (
                            <Button
                                key={item.name}
                                component={Link}
                                to={item.path}
                                sx={{ color: '#fff' }}>
                                {item.name}
                            </Button>
                        ))}
                        {profile ? (
                            <Tooltip title="Mon compte">
                                <IconButton sx={{ p: 0 }}>
                                    <Avatar alt={profile?.fullName} src={avatar} />
                                </IconButton>
                            </Tooltip>
                        ) : null}

                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </Fragment >
    );
}