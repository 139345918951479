import { createTheme, ThemeOptions } from "@mui/material";
import OpenSansBold from '@/assets/fonts/OpenSans-Bold.ttf';
import OpenSansBoldItalic from '@/assets/fonts/OpenSans-BoldItalic.ttf';
import OpenSansExtraBold from '@/assets/fonts/OpenSans-ExtraBold.ttf';
import OpenSansExtraBoldItalic from '@/assets/fonts/OpenSans-ExtraBoldItalic.ttf';
import OpenSansItalic from '@/assets/fonts/OpenSans-Italic.ttf';
import OpenSansLight from '@/assets/fonts/OpenSans-Light.ttf';
import OpenSansLightItalic from '@/assets/fonts/OpenSans-LightItalic.ttf';
import OpenSansRegular from '@/assets/fonts/OpenSans-Regular.ttf';
import OpenSansSemiBold from '@/assets/fonts/OpenSans-SemiBold.ttf';
import OpenSansSemiBoldItalic from '@/assets/fonts/OpenSans-SemiBoldItalic.ttf';
// import LatoRegular from './assets/fonts/Lato-Regular.ttf';
// import LatoBold from '@/assets/fonts/Lato-Bold.ttf';
// import LatoBoldItalic from '@/assets/fonts/Lato-BoldItalic.ttf';
// import LatoExtraBold from '@/assets/fonts/Lato-ExtraBold.ttf';
// import LatoExtraBoldItalic from '@/assets/fonts/Lato-ExtraBoldItalic.ttf';
// import LatoItalic from '@/assets/fonts/Lato-Italic.ttf';
// import LatoLight from '@/assets/fonts/Lato-Light.ttf';
// import LatoLightItalic from '@/assets/fonts/Lato-LightItalic.ttf';
// import LatoThin from '@/assets/fonts/Lato-Thin.ttf';
// import LatoThinItalic from '@/assets/fonts/Lato-ThinItalic.ttf';

export const PrimaryColor = "#4B5980"

export const appThemeOptions: ThemeOptions = {
    typography: {
        fontFamily: [
            'Open Sans',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
        @font-face {
            font-family: 'Open Sans';
            src: url(${OpenSansBold}) format('truetype');
            font-weight: bold;
            font-style: normal;
        }
    
        @font-face {
            font-family: 'Open Sans';
            src: url(${OpenSansBoldItalic}) format('truetype');
            font-weight: bold;
            font-style: italic;
        }
        
        @font-face {
            font-family: 'Open Sans';
            src: url(${OpenSansExtraBold}) format('truetype');
            font-weight: bolder;
            font-style: normal;
        }
        
        @font-face {
            font-family: 'Open Sans';
            src: url(${OpenSansExtraBoldItalic}) format('truetype');
            font-weight: bolder;
            font-style: italic;
        }
        
        @font-face {
            font-family: 'Open Sans';
            src: url(${OpenSansItalic}) format('truetype');
            font-weight: normal;
            font-style: italic;
        }
        
        @font-face {
            font-family: 'Open Sans';
            src: url(${OpenSansLight}) format('truetype');
            font-weight: 300;
            font-style: normal;
        }
        
        @font-face {
            font-family: 'Open Sans';
            src: url(${OpenSansLightItalic}) format('truetype');
            font-weight: 300;
            font-style: italic;
        }
        
        @font-face {
            font-family: 'Open Sans';
            src: url(${OpenSansRegular}) format('truetype');
            font-weight: normal;
            font-style: normal;
        }
        
        @font-face {
            font-family: 'Open Sans';
            src: url(${OpenSansSemiBold}) format('truetype');
            font-weight: 600;
            font-style: normal;
        }
        
        @font-face {
            font-family: 'Open Sans';
            src: url(${OpenSansSemiBoldItalic}) format('truetype');
            font-weight: 600;
            font-style: normal;
        }
      `,
        },
    },
    palette: {
        // mode: 'dark',
        // mode: 'light',
        primary: {
            // light: will be calculated from palette.primary.main,
            main: PrimaryColor,
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: '#F7B47D'
        },
        // background: {
        //   // default: 'cyan',
        //   // paper: React.CSSProperties['color'],
        // },
        // background: {
        //   // main: React.CSSProperties['color'],
        //   main: ,
        // },
        // mutedLight: {
        //   main: '#c2c0c0',
        // },
        // mutedDark: {
        //   main: '#707070',
        // },
        // blackish: {
        //   main: '#000000',
        // },
        // blackish2: {
        //   main: '#323232',
        // },
        // whitish: {
        //   main: '#FFFFFF',
        // },
        // whitish2: {
        //   main: '#E6E6E6',
        // },
        // shadow: {
        //   main: '#6E7881',
        // },
        // shadowDark: {
        //   main: '#B1B1B1',
        // },
    },
}
const appTheme = createTheme(appThemeOptions);


export default appTheme